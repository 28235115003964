import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Blog and slide`}</h2>
    <ProjectCard title="ベンチマークより力比べしたくない？" link="https://www.slideshare.net/ssuser329cba/ss-226864064" bg="linear-gradient(to right, rgb(102, 45, 140) 0%, rgb(237, 30, 121) 100%)" mdxType="ProjectCard">
Step up Go for Students 2-4 発表資料
    </ProjectCard>
    <ProjectCard title="wireをプロダクトに導入してみたという話" link="https://www.slideshare.net/ssuser329cba/wire-201371818" bg="linear-gradient(to right, rgb(0, 146, 69) 0%, rgb(252, 238, 33) 100%)" mdxType="ProjectCard">
golang.tokyo#28 発表資料
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      